<template>
  <div class="home">
    <h1>About Us</h1>
  </div>
  <div class="uscis-tracker">
    <h1>Welcome to immigrationus.fyi</h1>
    <p>Our website provides you with daily updates on the status of USCIS cases, using public data from the USCIS
      organization. Our goal is to make it easy for
      you to understand where your case stands in comparison to similar cases, so that you can be informed and stay
      up-to-date throughout your immigration journey.</p>
    <p>As someone who has gone through the USCIS process myself, I understand how frustrating and confusing it can be to
      navigate
      the system. That's why I created immigrationus.fyi - to help others like me stay informed and have a clear
      understanding of the status of their case.</p>
    <p>Our website get data from USCIS via the following link: <a
        href="https://egov.uscis.gov/casestatus/mycasestatus.do?appReceiptNum=#{case_number}">https://egov.uscis.gov/casestatus/mycasestatus.do?appReceiptNum=#{case_number}</a>,
      and provides you with real-time updates on the status of your case. We believe that access to this information
      should be transparent and easily accessible for all, and that's why we created USCIS Tracker.</p>


    <div class="expand-div" v-if="caseByStatusSeries">
      <h3>Case by status</h3> <p>The chart below illustrates the number of new statuses we have recorded today, categorized by their respective status.</p>
      <apexchart width="100%" height="700" type="bar" :options="caseByStatusOptions" :series="caseByStatusSeries">
      </apexchart>
    </div>

    <div class="expand-div" v-if="series">
      <h3>Status of the system</h3>
      <p>The chart below shows the number of cases we have retrieved today to update our database.</p>
      <apexchart width="100%" height="700" type="bar" :options="options" :series="series"></apexchart>
    </div>
  </div>
</template>

<script>

import VueApexCharts from "vue3-apexcharts";
import axios from 'axios'


export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      options: null,
      caseByStatusOptions: null,
      series: null,
      caseByStatusSeries: null,
    };
  },
  mounted() {
    this.callApi();
  },
  methods: {
    async callApi() {
      try {
        axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getProcesStatusChartJson')
          .then(response => {
            this.options = response.data.options;
            this.series = response.data.series;
          })
          .catch(error => {
            console.log(error);
          });

      } catch (error) {
        console.error(error);
      }
      try {
        axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getCaseByStatusTodayChartJson')
          .then(response => {
            this.caseByStatusSeries = response.data.series;
            var optionsTemp = {
              chart: {
                type: 'bar',
                height: 'auto',
                stacked: true,
              },
              yaxis: {
                labels: {
                  show: true,
                  align: 'right',
                  minWidth: 0,
                  maxWidth: 250,
                  style: {
                    colors: [],
                    fontSize: '8px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                  },
                },
              },
              xaxis: {
                categories: response.data.options.xaxis.categories,
                convertedCatToNumeric: false           
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    position: 'top'
                  }
                }
              },
              dataLabels: {
                  enabled: true,
                  style: {
                    colors: ['#333']
                  },
                  offsetX: 30
                },
            }

            console.log(response.data.options);

            this.caseByStatusOptions = optionsTemp;

            console.log(optionsTemp);
          })
          .catch(error => {
            console.log(error);
          });

      } catch (error) {
        console.error(error);
      }
    },
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.expand-div {
  width: 100%;
}

.uscis-tracker {
  max-width: 1320px;
  margin: 0 auto;
  /* Add your other styles here */
}
</style>