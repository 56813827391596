<template>
  <div v-if="news">
    <v-row>
      <v-col cols="12">
        <h2>News Feed</h2>
      </v-col>
    </v-row>
    <v-row v-for="(category, index) in newsCategories" :key="index">
      <v-col cols="12">
        <h3>{{ category }}</h3>
      </v-col>
      <v-col cols="12" v-for="newsItem in getNewsByCategory(category)" :key="newsItem.id">
        <v-card>
          <v-img v-if="newsItem.image" :src="newsItem.image" alt="News Image"></v-img>
          <v-card-title>
            <router-link :to="`/news/details/${newsItem.id}`">{{ newsItem.title }}</router-link>
          </v-card-title>
          <v-card-text>{{ newsItem.description }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  beforeRouteEnter(to, from, next)
  {
    axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getSmallNewsSectionInfo')
      .then(response => {
        next(vm => vm.setData(response.data));
      })
      .catch(error => {
        console.error(error);
      });
  },
  data() {
    return {
      news: null,
    };
  },
  computed: {
    newsCategories() {
      // Get unique news categories
      return [...new Set(this.news.map((item) => item.category))];
    },
  },
  methods: {
    setData(data) {
      this.news = data;
    },
    getNewsByCategory(category) {
      // Filter news by category
      return this.news.filter((item) => item.category === category);
    },
  },
};
</script>
  
<style scoped>
.v-card__title,
.v-card__text {
  text-align: center;
}
</style>