<template>
    <div>
        <Dashboard v-if="data" :data="data" :title="titleval" />
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { ref, watch } from "vue";
import { getDashboardData } from '../core/dashboard.js'
import Dashboard from '@/dashboards/Dashboard.vue'; 
const data = ref(0)
const route = useRoute()
const titleval = route.params.id.charAt(0) + '-' + route.params.id.slice(1) + ' Dashboard 2023 Fisical Year';
const { dashboard_data } = getDashboardData(route.params.id)
watch(dashboard_data, (newValue, oldValue) => {
    data.value = newValue.data
})
</script>