import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "../home/HomePage.vue";
import About from "../info/About.vue";
import Disclaimer from "../info/Disclaimer.vue";
import PrivacyPolicy from "../info/PrivacyPolicy.vue";
import NumberOfCases from "../info/NumberOfCases.vue";
import I485PermanentResidence from "../casetypes/I485PermanentResidence.vue";
import I140ImmigrantPetition from "../casetypes/I140ImmigrantPetitionForAlienWorler.vue";
import I765EmploymentAuthorization from "../casetypes/I765EmploymentAuthorization.vue";
import News from "../news/News.vue";
import NewsDetail from "../news/NewsDetail.vue";
import CaseTypeDashboard from "../dashboards/CaseTypeDashboard.vue";

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: HomePage,
    },
    {
      path: "/about",
      name: "About",
      component: About,
    },
    {
      path: "/CaseTypeDashboard/:id",
      name: "CaseTypeDashboard",
      component: CaseTypeDashboard,
    },
    {
      path: "/I485",
      name: "I485PermanentResidence",
      component: I485PermanentResidence,
    },
    {
      path: "/I140",
      name: "I140ImmigrantPetition",
      component: I140ImmigrantPetition,
    },
    {
      path: "/I765",
      name: "I765EmploymentAuthorization",
      component: I765EmploymentAuthorization,
    },
    {
      path: "/news",
      name: "News",
      component: News,
    },
    {
      path: "/numbercases",
      name: "NumberCases",
      component: NumberOfCases,
    },
    {
      path: "/news/details/:id",
      name: "NewsDetail",
      component: NewsDetail,
    },
    {
      path: "/disclaimer",
      name: "Disclaimer",
      component: Disclaimer,
    },
    {
      path: "/privacy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
    },
  ],
});