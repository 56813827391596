<template>
  <v-container class="uscis-tracker" fluid>
    <v-row width="100%" class="home">
      <form class="div_home__form" @submit.prevent="callApi('origin')">
        <v-col v-if="caseNumFormvalue !== '' && !validInput">
          <div class="form_error_message" style="color: red;">
            The format of your input is not valid. To be accepted, it must follow the specific format a USCIS case number.
          </div>
        </v-col>
        <v-col v-if="showErrorUnableToGetCaseInfo">
          <div class="form_error_message">
            The case does not exist in our database.
          </div>
        </v-col>
        <v-col>
          <label for="input-field" class="text-h5 home__label">Enter you case number</label>
        </v-col>
        <v-col>
          <v-text-field id="input-field" v-model="caseNumFormvalue" clearable label="Case"
            variant="outlined"></v-text-field>
        </v-col>
        <v-col>
          <v-btn variant="outlined" :disabled="!isValidInput" type="submit">
            Search
          </v-btn>
        </v-col>
      </form>
      <div class="statistics-message" v-if="showUnknowTypeErrorMessage">
        <p>Our system was unable to determine the type of your case, and as a result, we are unable to display all of the
          statistics.
          However, you can provide your case status by filling out the form at the bottom of the page to request the
          statistics.</p>
      </div>
    </v-row>
    <v-row v-if="jsonData" class="timeline-container">
      <v-sheet width="80%" class="mx-auto">
      <v-col cols="12">
        <h2 class="text-h5 text-center">Case Information</h2>
      </v-col>
      <v-col cols="12">
      <div class="timeline-details">
        <span class="font-weight-bold">Case Number: </span> <span class="text-body-1"> {{ jsonData.CaseNum }} </span> <br>
        <span class="font-weight-bold">Last Action Date: </span> <span class="text-body-1"> {{ jsonData.LastActionDate }} </span><br>
        <span class="font-weight-bold">Status: </span> <span class="text-body-1"> {{ jsonData.Status }} </span><br>
        <span class="font-weight-bold">Type: </span> <span class="text-body-1"> {{ jsonData.Type }} </span><br>
      </div>
    </v-col>
    <v-col cols="12">
      <ul class="timeline-list" v-if="jsonData">
        <li v-for="item in jsonData.TimeLine" :key="item.LastActionDate">
          <div class="timeline-item">
            <div class="timeline-item-details">
              <p class="timeline-date">{{ item.LastActionDate }}</p>
              <p class="timeline-status">{{ item.Status }}</p>
            </div>
          </div>
        </li>
      </ul>
    </v-col>
  </v-sheet>
    </v-row>
    <v-row v-if="series">
      <v-col>
        <v-sheet class="mx-auto">
          <v-slide-group show-arrows>
            <v-slide-group-item v-for="(item, index) in items" :key="index">
              <v-btn class="ma-2" rounded :color="isSelected(item) ? 'primary' : undefined"
                @click="handleItemClick(item)">
                {{ item.text }}
              </v-btn>
            </v-slide-group-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-slide-group show-arrows>
          <v-slide-group-item v-for="(item, index) in itemsCenters" :key="index">
            <v-btn class="ma-2" rounded :color="isCenterSelected(item) ? 'primary' : undefined"
              @click="handleCenterItemClick(item)">
              {{ item.text }}
            </v-btn>
          </v-slide-group-item>
        </v-slide-group>
      </v-col>
      <v-col cols="12">
        <apexchart width="100%" height="700" type="bar" :options="options" :series="series"></apexchart>
      </v-col>
    </v-row>
    <v-row class="container" v-if="showData">
      <v-col cols="12" md="4" v-if="approvedStatusSlace" class="align-self-start">
        <div class="card">
          <h2 class="card-title">Approved Status</h2>
          <ul v-if="approvedStatusSlace.length" class="card-list">
            <li v-for="(item) in approvedStatusSlace" class="card-item">
              <form :action="uscisUrl" method="POST" target="_blank">
                <input type="hidden" name="appReceiptNum" :value="item.split(',')[0]" />
                <button type="submit" class="item-link">{{ item.split(',')[0] }}</button>
              </form>
              <span class="item-date">{{ formatDate(item.split(',')[1] + item.split(',')[2]) }}</span>
            </li>
          </ul>
          <p v-else>Not item found</p>
        </div>
      </v-col>
      <v-col cols="12" md="4" v-if="RFEStatusSlace" class="align-self-start">
        <div class="card">
          <h2 class="card-title">RFE Status</h2>
          <ul v-if="RFEStatusSlace.length" class="card-list">
            <li v-for="(item) in RFEStatusSlace" class="card-item">
              <form :action="uscisUrl" method="POST" target="_blank">
                <input type="hidden" name="appReceiptNum" :value="item.split(',')[0]" />
                <button type="submit" class="item-link">{{ item.split(',')[0] }}</button>
              </form>
              <span class="item-date">{{ formatDate(item.split(',')[1] + item.split(',')[2]) }}</span>
            </li>
          </ul>
          <p v-else>Not item found</p>
        </div>
      </v-col>
      <v-col cols="12" md="4" v-if="deniedStatusSlace" class="align-self-start">
        <div class="card">
          <h2 class="card-title">Denied Status</h2>
          <ul v-if="deniedStatusSlace.length" class="card-list">
            <li v-for="(item) in deniedStatusSlace" class="card-item">
              <form :action="uscisUrl" method="POST" target="_blank">
                <input type="hidden" name="appReceiptNum" :value="item.split(',')[0]" />
                <button type="submit" class="item-link">{{ item.split(',')[0] }}</button>
              </form>
              <span class="item-date">{{ formatDate(item.split(',')[1] + item.split(',')[2]) }}</span>
            </li>
          </ul>
          <p v-else>Not item found</p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="statistics-message" v-if="showData">
          <p>If the statistics are not showing, it may be because we don't have all the information needed.</p>
        </div>
        <div class="form-container" v-if="showData">
          <div class="form_error_message" v-if="caseNumber !== '' && !secondValidInput" style="color: red;">
            The format of your input is not valid. To be accepted, it must follow the specific format a USCIS case number.
          </div>
          <form @submit.prevent="callApi('backup')">
            <label for="caseNumber">Case Number:</label>
            <input type="text" v-model="caseNumber" id="caseNumber" name="caseNumber" required>
            <label for="caseType">Case Type:</label>
            <input type="text" v-model="caseType" id="caseType" name="caseType" required>
            <button type="submit">Submit</button>
          </form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import axios from 'axios'

export default {
  name: 'HelloWorld',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      showUnknowTypeErrorMessage: false,
      validInput: false,
      secondValidInput: false,
      caseNumber: '',
      caseType: '',
      uscisUrl: 'https://egov.uscis.gov/casestatus/mycasestatus.do',
      options: null,
      series: null,
      caseNumFormvalue: '',
      response: '',
      jsonData: null,
      approveStatus: null,
      approvedStatusSlace: null,
      deniedStatus: null,
      deniedStatusSlace: null,
      RFEStatus: null,
      RFEStatusSlace: null,
      showData: null,
      caseExists: true,
      lastSelected: null,
      centerLastSelected: null,
      items: [
        { id: 1, text: 'All' },
        { id: 2, text: 'I-140' },
        { id: 3, text: 'I-765' },
        { id: 4, text: 'I-485' },
        { id: 5, text: 'I-130' },
        { id: 6, text: 'I-129' },
        { id: 7, text: 'I-131' },
        { id: 8, text: 'I-730' },
        { id: 9, text: 'I-751' },
        { id: 10, text: 'I-102' },
        { id: 11, text: 'I-212' },
        { id: 12, text: 'I-360' },
        { id: 13, text: 'I-526' },
        { id: 14, text: 'I-539' },
        { id: 15, text: 'I-601' },
        { id: 16, text: 'I-817' },
        { id: 17, text: 'I-824' },
        { id: 18, text: 'I-829' },
        { id: 19, text: 'I-865' },
      ],
      itemsCenters: [
        { id: 1, text: 'All' },
        { id: 2, text: 'LIN_lockbox' },
        { id: 3, text: 'LIN_service_center' },
        { id: 4, text: 'MSC_lockbox' },
        { id: 5, text: 'MSC_service_center' },
        { id: 6, text: 'SRC_lockbox' },
        { id: 7, text: 'SRC_service_center' },
        { id: 8, text: 'WAC_lockbox' },
        { id: 9, text: 'WAC_service_center' },
        { id: 10, text: 'YSC_lockbox' },
      ],
    };
  },
  watch: {
    caseNumFormvalue(newValue, oldValue) {
      const regex = /^[A-Z]{3}\d{10}$/
      this.validInput = regex.test(newValue)
    },
    caseNumber(newValue, oldValue) {
      const regex = /^[A-Z]{3}\d{10}$/
      this.secondValidInput = regex.test(newValue)
    }
  },
  computed: {
    names() {
      return this.jsonData.TimeLine.map((item) => item.LastActionDate);
    },
    isSelected() {
      return item => item === this.lastSelected;
    },
    isCenterSelected() {
      return item => item === this.centerLastSelected;
    },
    oldStatusDate() {
      const MS_PER_DAY = 1000 * 60 * 60 * 24;
      const sortedArray = this.jsonData.TimeLine.slice().sort((a, b) => {
        const dateA = new Date(a.LastActionDate);
        const dateB = new Date(b.LastActionDate);
        return dateA - dateB;
      });
      const oldestDate = new Date(sortedArray[0].LastActionDate);
      const dateObj = new Date(oldestDate);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObj.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}${month}${day}`;
      return formattedDate;
    },
    isValidInput() {
      if (this.caseNumFormvalue === '') {
        return false
      }
      const regex = /^[A-Z]{3}\d{10}$/
      return regex.test(this.caseNumFormvalue)
    },
    showError() {
      return this.caseNumFormvalue !== '' && !this.isValidInput
    },
    showErrorUnableToGetCaseInfo() {
      return this.caseExists === false
    }
  },
  methods: {
    handleItemClick(item) {
      this.lastSelected = item;
      if (item.text === 'All') {
        if (this.centerLastSelected.text === "All") {
          this.options = this.unknowOptions;
          this.series = this.unknowSeries;
        } else {
          axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getCaseStadisticByCeter?caseDate=' + this.oldStatusDate + '&center=' + this.centerLastSelected.text + '')
            .then(response => {
              this.options = response.data.options;
              this.series = response.data.series;
            })
            .catch(error => {
              console.log(error);
            });
        }
      } else {
        if (this.centerLastSelected.text === "All") {
          axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getCaseStadistictsData?caseType=' + item.text.replace("-", "") + '&caseDate=' + this.oldStatusDate + '')
            .then(response => {
              this.options = response.data.options;
              this.series = response.data.series;
            })
            .catch(error => {
              console.log(error);
            });
        }
        else {
          axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getCaseStadisticByCaseTypeAndCenter?caseDate='
            + this.oldStatusDate
            + '&center=' + this.centerLastSelected.text
            + '&caseType=' + item.text.replace("-", "") + '')
            .then(response => {
              this.options = response.data.options;
              this.series = response.data.series;
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    },
    handleCenterItemClick(item) {
      this.centerLastSelected = item;
      if (item.text === 'All') {
        if (this.lastSelected.text === "All") {
          this.options = this.unknowOptions;
          this.series = this.unknowSeries;
        }
        else {
          axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getCaseStadistictsData?caseType=' + this.lastSelected.text.replace("-", "") + '&caseDate=' + this.oldStatusDate + '')
            .then(response => {
              this.options = response.data.options;
              this.series = response.data.series;
            })
            .catch(error => {
              console.log(error);
            });
        }
      } else {
        if (this.lastSelected.text === "All") {
          axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getCaseStadisticByCeter?caseDate=' + this.oldStatusDate + '&center=' + item.text + '')
            .then(response => {
              this.options = response.data.options;
              this.series = response.data.series;
            })
            .catch(error => {
              console.log(error);
            });
        }
        else {
          axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getCaseStadisticByCaseTypeAndCenter?caseDate='
            + this.oldStatusDate
            + '&center=' + item.text
            + '&caseType=' + this.lastSelected.text.replace("-", "") + '')
            .then(response => {
              this.options = response.data.options;
              this.series = response.data.series;
            })
            .catch(error => {
              console.log(error);
              this.series = [];
            });
        }
      }
    },
    generateLink(item) {
      const receiptNumber = item.split(',')[0];
      return `https://egov.uscis.gov/casestatus/mycasestatus.do?appReceiptNum=${receiptNumber}`;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    async callApi(formId) {
      try {
        this.showData = null;
        this.jsonData = null;
        this.response = null;
        this.options = null;
        this.series = null;
        this.unknowOptions = null;
        this.unknowSeries = null;
        this.approveStatus = null;
        this.approvedStatusSlace = null;
        this.deniedStatus = null;
        this.deniedStatusSlace = null;
        this.RFEStatus = null;
        this.RFEStatusSlace = null;

        if (formId === 'backup') {
          this.caseNumFormvalue = this.caseNumber;
          this.caseType = this.caseType;
        }
        else {
          this.caseNumber = '';
          this.caseType = '';
        }

        axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getDocument?id=' + this.caseNumFormvalue)
          .then(response => {
            this.jsonData = response.data;
            this.response = response.data;
            this.unknowOptions = response.data.options;
            this.unknowSeries = response.data.series;
            this.caseNumber = this.caseNumFormvalue;
            if (formId === 'origin' || this.caseType === '') {
              this.caseType = this.jsonData.Type;
            }
            this.caseExists = true;

            if (this.caseType === 'unknow') {
              this.showUnknowTypeErrorMessage = true;
              this.options = response.data.options;
              this.series = response.data.series;
              this.lastSelected = this.items[0];
              this.centerLastSelected = this.itemsCenters[0];
            } else {

              this.lastSelected = this.items.filter(item => item.text.toLowerCase().includes(this.caseType.toLowerCase()))[0];
              this.centerLastSelected = this.itemsCenters[0];
              this.showUnknowTypeErrorMessage = false;

              axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getCaseStadistictsData?caseType=' + this.caseType.replace("-", "") + '&caseDate=' + this.oldStatusDate + '')
                .then(response => {
                  this.options = response.data.options;
                  this.series = response.data.series;
                })
                .catch(error => {
                  console.log(error);
                  this.approveStatus = [];
                  this.approvedStatusSlace = [];
                });

              axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getApprovedStatus?caseType=' + this.caseType + '&caseDate=' + this.oldStatusDate + '')
                .then(response => {
                  this.approveStatus = response.data.options.xaxis.categories;
                  this.approvedStatusSlace = this.approveStatus.slice(0, 10);
                })
                .catch(error => {
                  console.log(error);
                  this.approveStatus = [];
                  this.approvedStatusSlace = [];
                });

              axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getDeniedStatus?caseType=' + this.caseType + '&caseDate=' + this.oldStatusDate + '')
                .then(response => {
                  this.deniedStatus = response.data.options.xaxis.categories;
                  this.deniedStatusSlace = this.deniedStatus.slice(0, 10);
                })
                .catch(error => {
                  console.log(error);
                  this.deniedStatus = [];
                  this.deniedStatusSlace = [];
                });

              axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getRFEStatus?caseType=' + this.caseType + '&caseDate=' + this.oldStatusDate + '')
                .then(response => {
                  this.RFEStatus = response.data.options.xaxis.categories;
                  this.RFEStatusSlace = this.RFEStatus.slice(0, 10);
                })
                .catch(error => {
                  console.log(error);
                  this.RFEStatus = [];
                  this.RFEStatusSlace = [];
                });
            }
            this.showData = true;
          })
          .catch(error => {
            this.caseExists = false;
            console.log("The case does not exist");
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 3px;
  padding: 3px;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  font-size: smaller;
}


.selected {
  background-color: #ccc;
}

.form-container {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
}

.form-container form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.form-container input {
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-container button {
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  background-color: #2196f3;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #0d8bf5;
}

.statistics-message {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
}

.statistics-message p {
  font-size: 18px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.timeline-container {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.timeline-details {
  margin-bottom: 20px;
}

.timeline-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.timeline-item {
  display: flex;
  margin-bottom: 20px;
}

.timeline-item-details {
  border-left: 3px solid #3f51b5;
  padding: 10px 20px;
}

.timeline-date {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.timeline-status {
  margin-top: 5px;
  margin-bottom: 0;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.home__text {
  text-align: center;
  margin-bottom: 50px;
}

.home__title {
  font-size: 48px;
  margin-bottom: 10px;
}

.home__subtitle {
  font-size: 24px;
}

.form_error_message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.div_home__form {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
}

.home__form {
  display: flex;
  justify-content: center;
  align-items: center;

}

.home__label {
  font-size: 18px;
  margin-right: 20px;
}

.home__input-wrapper {
  display: flex;
  align-items: center;
}

.home__input {
  width: 300px;
  height: 40px;
  font-size: 24px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  margin-right: 10px;
}

.home__button {
  font-size: 24px;
  padding: 10px 20px;
  background-color: #4285f4;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home__button:hover {
  background-color: #3367d6;
}

.container {
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f7fafc;
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px;
}

.card-wrapper {
  flex-basis: 32%;
  padding: 10px;
  box-sizing: border-box;
}

.card {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px;
  max-width: 90%;
  width: 100%;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #3c4858;
  text-align: center;
}

.card-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.card-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e9ecef;
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

.item-link {
  font-weight: bold;
  margin-right: 10px;
  color: #007bff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
}

.item-link:hover {
  text-decoration: none;
}

.item-date {
  font-style: italic;
  color: #999;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.uscis-tracker {
  max-width: 1320px !important;
  margin: 0 auto;
  /* Add your other styles here */
}
</style>
