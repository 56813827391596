import { reactive, ref } from 'vue'
import axios from 'axios'

export function getDashboardData(caseTypeKey) {
    const dashboard_data = reactive({
        data: null,
        error: null
    })
    
    const fetch = async () => {
        await axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getDashboard?id=collection' + caseTypeKey)
            .then((res) => {
                dashboard_data.data = res.data
            })
            .catch((err) => {
                dashboard_data.error = err
            })
    };

    fetch();
    return { dashboard_data };
}