<template>
  <div v-if="news">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ news.title }}</v-card-title>
          <v-card-subtitle>{{ news.category }}</v-card-subtitle>
          <v-card-text>
            <div v-html="news.description"></div>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" v-for="(section, title) in news.sections" :key="title">
                <div v-html="section"></div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-for="(reference, key) in news.references" :key="key" outlined :href="reference.content" target="_blank">For more information visit the following link: {{ reference.title }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  beforeRouteEnter(to, from, next) {
    console.log(to.params.id);
    axios.get('https://us-central1-immigration-86c90.cloudfunctions.net/getNews?id=' + to.params.id)
      .then(response => {
        next(vm => vm.setData(response.data));
      })
      .catch(error => {
        console.error(error);
      });
  },
  data() {
    return {
      news: null,
    };
  },
  computed: {
    newsCategories() {
      return [...new Set(this.news.map((item) => item.category))];
    },
  },
  methods: {
    setData(data) {
      this.news = data;
      const sectionsArray = Object.entries(this.news.sections);
      sectionsArray.sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
      const sortedSections = {};
      sectionsArray.forEach(([key, value]) => {
        sortedSections[key] = value;
      });
      this.news.sections = sortedSections;     
      console.log(this.news);     
    },
  },
};
</script>
    
<style scoped>
.v-card__title,
.v-card__text {
  text-align: center;
}
</style>