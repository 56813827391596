<template>
  <v-app>
    <v-container class="main-container">
      <v-toolbar class="toolbar" v-if="!this.$vuetify.display.mobile">
        <v-btn variant="plain" to="/" @click="refreshContent">
          <v-img src="logo.png" height="30px" width="40px">
          </v-img>
        </v-btn>
        <v-toolbar-title class="tool-bar-title flex text-left text-h5">
          Immigration Data
        </v-toolbar-title>
        <v-btn class="tool-bar-title" to="/about">About Us</v-btn>
        <v-btn class="tool-bar-title" to="/news">News</v-btn>
        <v-btn class="tool-bar-title" to="/numbercases">Number of cases</v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn class="tool-bar-title" v-bind="props">Case Types</v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(caseType, index) in caseTypes" :key="index" :to="caseType.link">
              <v-list-item-title class="tool-bar-title"> {{ caseType.name }} </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, props }">
            <v-btn class="tool-bar-title" v-bind="props">Dashboards</v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(dashboard, index) in dashboards" :key="index" :to="dashboard.link">
              <v-list-item-title class="tool-bar-title">{{ dashboard.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-toolbar class="toolbar" v-if="this.$vuetify.display.mobile">
        <v-btn variant="plain" to="/">
          <v-img src="logo.png" height="30px" width="40px">
          </v-img>
        </v-btn>
        <v-toolbar-title class="mobile-tool-bar-title flex text-left text-h5">
          Immigration Data
        </v-toolbar-title>
        <v-btn class="mobile-tool-bar-title hidden-md-and-up text-h5" @click.stop="sidebar = !sidebar">|||</v-btn>
        <v-navigation-drawer class="navigation-drawer" location="right" v-model="sidebar" temporary>
          <v-btn to="/about">About Us</v-btn> <br>
          <v-btn to="/news">News</v-btn> <br>
          <v-btn to="/numbercases">Number of cases</v-btn> <br>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, props }">
              <v-btn v-bind="props">Case Types</v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(caseType, index) in caseTypes" :key="index" :to="caseType.link">
                <v-list-item-title> {{ caseType.name }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, props }">
              <v-btn v-bind="props">Dashboards</v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(dashboard, index) in dashboards" :key="index" :to="dashboard.link">
                <v-list-item-title>{{ dashboard.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-navigation-drawer>
      </v-toolbar>
    </v-container>
    <v-main @click.stop="sidebar = sidebar ? false : sidebar">
      <v-container class="router-container" fluid>
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>
    <v-footer class="footer" v-if="!this.$vuetify.display.mobile">
      <v-row justify="center" class="footer-row" no-gutters>
        <v-col class="text-right mt-4" cols="12" sm="6">
          <span class="company-span">© 2023 IronTraveler Labs</span>
        </v-col>
        <v-col class="text-left mt-4" cols="12" sm="6">
          <a class="footer-link" href="/">Home</a> |
          <a class="footer-link" href="#/about">About Us</a> |
          <a class="footer-link" href="#/disclaimer">Disclaimer</a> |
          <a class="footer-link" href="#/privacy">Privacy Policy</a>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer class="footer" v-if="this.$vuetify.display.mobile">
      <v-row justify="center" class="footer-row" no-gutters>
        <v-col class="text-center mt-4" cols="12" sm="6">
          <span class="company-span">© 2023 IronTraveler Labs</span>
        </v-col>
        <v-col class="text-center mt-4" cols="12" sm="6">
          <a class="footer-link" href="/">Home</a> |
          <a class="footer-link" href="#/about">About Us</a> |
          <a class="footer-link" href="#/disclaimer">Disclaimer</a> |
          <a class="footer-link" href="#/privacy">Privacy Policy</a>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    rail: true,
    sidebar: false,
    caseTypes: [
      { name: 'I-485 Permanent Residence', link: '/I485' },
      { name: 'I-765 Employment Authorization', link: '/I765' },
      { name: 'I-140 Immigrant Petition for Alien Worker', link: '/I140' },
    ],
    dashboards: [
      { name: 'I-485 Permanent Residence', link: '/CaseTypeDashboard/I485' },
      { name: 'I-765 Employment Authorization', link: '/CaseTypeDashboard/I765' },
      { name: 'I-140 Immigrant Petition for Alien Worker', link: '/CaseTypeDashboard/I140' },
      { name: 'I-730 Refugee/Asylee Relative Petition', link: '/CaseTypeDashboard/I730' },
      { name: 'I-130 Petition for Alien Relative', link: '/CaseTypeDashboard/I130' },
      { name: 'I-129 Petition for a Nonimmigrant Worker', link: '/CaseTypeDashboard/I129' },
      { name: 'I-131 Application for Travel Document', link: '/CaseTypeDashboard/I131' },
      { name: 'I-751 Petition to Remove Conditions on Residence', link: '/CaseTypeDashboard/I751' },
      { name: 'I-102 Application for Replacement/Initial Nonimmigrant Arrival-Departure Document', link: '/CaseTypeDashboard/I102' },
      { name: 'I-212 Application for Permission to Reapply for Admission into the United States After Deportation or Removal', link: '/CaseTypeDashboard/I212' },
      { name: 'I-360 Petition for Amerasian, Widow(er), or Special Immigrant', link: '/CaseTypeDashboard/I360' },
      { name: 'I-526 Immigrant Petition by Standalone Investor', link: '/CaseTypeDashboard/I526' },
      { name: 'I-539 Application to Extend/Change Nonimmigrant Status', link: '/CaseTypeDashboard/I539' },
      { name: 'I-601 Application for Waiver of Grounds of Inadmissibility', link: '/CaseTypeDashboard/I601' },
      { name: 'I-817 Application for Family Unity Benefits', link: '/CaseTypeDashboard/I817' },
      { name: 'I-824 Application for Action on an Approved Application or Petition', link: '/CaseTypeDashboard/I824' },
      { name: 'I-829 Petition by Investor to Remove Conditions on Permanent Resident Status', link: '/CaseTypeDashboard/I829' },
      { name: 'I-865 Sponsor Notice of Change of Address', link: '/CaseTypeDashboard/I865' },
    ]
  }),
  methods: {
    refreshContent() {
      console.log('refreshing content');
    },
  }
}
</script>

<style>
.main-container {
  max-width: 100% !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tool-bar-title {
  color:#000000 !important;
}

.mobile-tool-bar-title {
  color:#000000 !important;
}

.navigation-drawer {
  position: relative;
  top: 65px !important;
}

.router-container {
  max-width: 1320px !important;

}

.toolbar {
  background-color: #ffffff !important;
  color: #fff;

}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

#app {
  margin-top: 0px;
  justify-content: center;
  align-items: center;
}

.footer {

  text-align: center;
  max-height: 50px;
  height: 50px;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 10px;
  padding-right: 0;
}

.footer-link {
  color: #1967d2;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.footer-row {
  height: 100%;
  align-items: flex-end;
}

.company-span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
  margin-right: 10px;
}
</style>
