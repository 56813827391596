<template>
  <div class="home text-center">
    <h1>Number of Cases</h1>
  </div>
  <div class="note-row">
    <div class="gray-background">
      <!-- Content goes here -->
      The following graph shows the data of cases managed by USCIS. The data is
      updated every 2 hours. The process to capture this data began in September
      2023.<br />
      <slot>- The system has data of the fiscal year starting on october of 2022
        and 2023</slot>
    </div>
  </div>
  <v-tabs v-model="tab" align-tabs="center">
    <v-tab :value="2023">2023</v-tab>
    <v-tab :value="2024">2024</v-tab>
  </v-tabs>
  <v-window v-model="tab">
    <v-window-item v-for="(item, index) in years" :key="item.value" :value="item.value">
      <v-container fluid>
        <div v-if="numbers">
          <v-row v-for="(listYears, index) in filterNumbers" :key="index">
            <v-col cols="12" class="text-center">
              <h2>
                Information of the fiscal ending on September of the: 20{{
                  listYears.year
                }}
              </h2>
            </v-col>
            <v-col cols="12">
              <v-row v-for="(item, index) in listYears.items" :key="index">
                <v-col cols="12" class="text-center" style="padding-top: 0px !important">
                  <div>
                    <h3 style="margin-top: 0 !important">{{ item.name }}</h3><br />
                    <h5 style="margin-top: 0 !important">Number of cases: {{ Number(item.max_value).toLocaleString() }}</h5>
                    <p><b>Time Updated:</b> {{ formattedTime(item.time_updated) }}</p>
                  </div>
                </v-col>
                <v-col cols="12" class="text-center">
                  <div class="d-flex align-center justify-center" style="width: 100%">
                    <img :src="item.url" alt="Vue.js Image" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-window-item>
  </v-window>
</template>

<script>
import axios from "axios";
export default {
  beforeRouteEnter(to, from, next) {
    axios
      .get(
        "https://us-central1-immigration-86c90.cloudfunctions.net/getNumberOfCases"
      )
      .then((response) => {
        next((vm) => vm.setData(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  },
  computed: {
    filterNumbers() {
      const result = this.listsByYear.filter((item) => item.year.toString().slice(-2) === this.tab.toString().slice(-2));
      return result;
    },
  },
  data() {
    return {
      numbers: null,
      listsByYear: null,
      tab: 2024,
      years: [
        { text: "2023", value: 2023 },
        { text: "2024", value: 2024 },
      ],
    };
  },
  methods: {
    setData(data) {
      this.numbers = data;

      const uniqueYears = [...new Set(this.numbers.map((item) => item.year))];

      this.listsByYear = uniqueYears.map((year) => {
        return {
          year: year,
          items: this.numbers.filter((item) => item.year === year),
        };
      });
      this.listsByYear.sort((a, b) => b.year - a.year);
    },
    formattedTime(time_updated) {
      const timestampInSeconds = time_updated._seconds;
      const timestampInMilliseconds = timestampInSeconds * 1000; // Convert to milliseconds

      const date = new Date(timestampInMilliseconds);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
        timeZone: "UTC",
      };
      return date.toLocaleString("en-US", options) + " -4";
    },
  },
};
</script>

<style>
.note-row {
  display: flex;
  /* Use flexbox */
  justify-content: center;
  /* Center-align horizontally */
  margin-bottom: 10px;
  /* Add margin to separate rows */
}

.gray-background {
  width: 50%;
  background-color: #f0f0f0;
  /* Gray background color */
  padding: 10px;
  /* Adjust padding as needed */
  border: 1px solid #ccc;
  /* Optional border for clarity */
  font-size: small;
}
</style>