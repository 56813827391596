<template>
    <v-row>
        <v-col cols="12">
            <div class="title">{{ title }}</div>
        </v-col>
    </v-row>
    <v-tabs v-model="tab" align-tabs="center">
        <v-tab :value="2023">2023</v-tab>
        <v-tab :value="2024">2024</v-tab>
    </v-tabs>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-card class="dashboard-card">
                    <div class="card-content">
                        <h3 class="card-title">Total Received</h3>
                        <p class="card-value">{{ data[this.tab].totalReceived }}</p>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card class="dashboard-card">
                    <div class="card-content">
                        <h3 class="card-title">Total Approved</h3>
                        <p class="card-value">{{ data[this.tab].totalApproved }}</p>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card class="dashboard-card">
                    <div class="card-content">
                        <h3 class="card-title">Total RFE</h3>
                        <p class="card-value">{{ data[this.tab].totalRFE }}</p>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card class="dashboard-card">
                    <div class="card-content">
                        <h3 class="card-title">Total Denied</h3>
                        <p class="card-value">{{ data[this.tab].totalRejected }}</p>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        Status Table
                    </v-card-title>
                    <v-card-text>
                        <table class="status-table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, title) in data[this.tab].totalByStatus" :key="title">
                                    <td>{{ title }}</td>
                                    <td>{{ value }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'Dashboard',
    props: {
        title: String,
        data: Object,
    },
    computed: {
        filterByYear() {
            const result = this.$data.filter((item) => item.year.toString().slice(-2) === this.tab.toString().slice(-2));
            return result;
        },
    },
    data() {
        return {
            tab: 2024,
            years: [
                { text: "2023", value: 2023 },
                { text: "2024", value: 2024 },
            ],
            dashboards: null,
        }
    },
};
</script>

<style scoped>
.dashboard-card {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-content {
    text-align: center;
}

.card-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
}

.card-value {
    font-size: 24px;
    font-weight: bold;
}

.status-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.status-table th,
.status-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.status-table th {
    font-weight: bold;
}
</style>