<template>
    <div>
        <h1>I-140 Immigrant Petition for Alien Worker</h1>
    </div>
    <p>The I-140 is a form used for filing an Immigrant Petition for Alien Worker. It is submitted to the United States
        Citizenship and Immigration Services (USCIS) by a U.S. employer on behalf of a foreign national who is seeking to
        immigrate to the United States for employment purposes.</p><br>

    <p>The purpose of the I-140 form is to establish that the foreign national meets the eligibility criteria for the
        employment-based immigrant visa category they are applying under. The I-140 petition is typically filed in
        conjunction with certain employment-based immigrant visa categories, including:</p><br>

    <ol>
        <li>EB-1 (First Preference): This category is for individuals with extraordinary ability in the sciences, arts,
            education, business, or athletics, outstanding professors or researchers, and multinational executives or
            managers.</li>
        <li>EB-2 (Second Preference): This category is for professionals holding advanced degrees or individuals with
            exceptional ability in the sciences, arts, or business.</li>
        <li>EB-3 (Third Preference): This category is for professionals, skilled workers, and other workers.</li>
        <li>EB-4 (Fourth Preference): This category is for special immigrants, including religious workers, broadcasters,
            Iraq/Afghan translators, and certain other groups.</li>
        <li>EB-5 (Fifth Preference): This category is for immigrant investors who are investing a certain amount of capital
            in a new commercial enterprise that creates jobs for U.S. workers.</li>
    </ol><br>

<p>When filing the I-140, the U.S. employer serves as the petitioner and is responsible for demonstrating that the job
    opportunity meets the requirements of the specific employment-based immigrant category. The employer must provide
    evidence of the company's ability to pay the proffered wage, as well as documentation supporting the qualifications
    and achievements of the foreign national.</p><br>

<p>The USCIS reviews the I-140 petition and assesses whether the foreign national meets the eligibility criteria for the
    requested employment-based immigrant visa category. If approved, the USCIS will issue an approval notice for the
    I-140 petition.</p><br>

<p>It's important to note that the approval of the I-140 petition does not grant the foreign national lawful permanent
    resident status or a green card. It establishes the eligibility for an employment-based immigrant visa. To obtain
    the green card, the foreign national typically needs to go through a separate process, such as filing an I-485
    application (Adjustment of Status) or going through consular processing outside the United States.</p><br>

<p>The I-140 process can be complex, and it's recommended to seek guidance from an immigration attorney or consult the
    USCIS website for specific information regarding the eligibility requirements and supporting documentation for your
    particular case.</p>
</template>