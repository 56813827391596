<template>
		<div class="page-body">
			<p>This Privacy Policy governs the privacy terms of our	Website, <a href="https://immigrationus.fyi/">https://immigrationus.fyi/</a>, as owned and operated by
				DEVSWEEK LLC</p> <br>
			<p><strong>Privacy Policy</strong></p> <br>
			<p>Last Updated: <strong>2023-06-08</strong></p> <br>
			<p>This Privacy Policy explains how we collect, use,
				disclose, and protect your personal information when you visit and interact with our website located at
				<a href="https://immigrationus.fyi/"><strong>https://immigrationus.fyi/</strong></a> (the
				&quot;Website&quot;). We are committed to respecting and protecting your privacy and complying with the
				General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and the
				California Privacy Rights and Enforcement Act (CPRA). By accessing or using our Website, you agree to
				the terms of this Privacy Policy.</p> <br>
			<p><strong>1. Information We Collect</strong></p><br>
			<p><strong>1.1 Personal Information</strong>
				We may collect personal information from you when you voluntarily provide it to us through forms on our
				Website, such as when you subscribe to our newsletter or contact us. The types of personal information
				we may collect include your name, email address, postal address, phone number, and any other information
				you choose to provide.</p><br>
			<p><strong>1.2 Automatically Collected
					Information</strong>
				When you visit our Website, certain information is automatically collected, including your IP address,
				browser type, operating system, referring website URLs, and information about your usage of our Website.
				This information helps us analyze trends, administer the Website, improve user experience, and gather
				demographic information.</p><br>
			<p><strong>1.3 Cookies and Similar Technologies</strong>
				We may use cookies and similar tracking technologies to collect information about your browsing
				activities on our Website. Cookies are small text files that are stored on your device when you visit a
				website, which enable us to recognize your device and enhance your experience on our Website. You can
				manage your cookie preferences through your browser settings.</p><br>
			<p><strong>2. Use of Information</strong></p><br>
			<p>We may use the information we collect for the
				following purposes:</p><br>
			<p><strong>2.1 Provide and Improve Services</strong>
				We may use your personal information to provide the services you have requested, respond to your
				inquiries, and communicate with you. We may also use the information to improve our Website, personalize
				your experience, and understand how users interact with our content.</p><br>
			<p><strong>2.2 Marketing and Communications</strong>
				With your consent where required by applicable law, we may send you newsletters, updates, and
				promotional materials about our services. You can unsubscribe from these communications at any time by
				following the instructions provided in the communication or by contacting us directly.</p><br>
			<p><strong>2.3 Compliance with Laws and
					Protection</strong>
				We may use your information to comply with applicable laws, regulations, and legal processes. We may
				also use it to protect our rights, privacy, safety, and property, as well as those of our users.</p><br>
			<p><strong>3. Disclosure of Information</strong></p><br>
			<p>We may disclose your information to the following
				categories of recipients:</p><br>
			<p><strong>3.1 Service Providers</strong>
				We may share your information with third-party service providers who perform services on our behalf,
				such as hosting providers, analytics providers, and email delivery services. These service providers
				have access to the information necessary to perform their functions but are not permitted to use it for
				other purposes.</p><br>
			<p><strong>3.2 Legal Obligations and Safety</strong>
				We may disclose your information in response to a legal request, to comply with applicable laws and
				regulations, to enforce our terms of service, or to protect the rights, property, or safety of our
				Website, users, or others.</p><br>
			<p><strong>3.3 Business Transfers</strong>
				In the event of a merger, acquisition, or other business transaction, we may transfer your information
				as part of the transaction. In such cases, we will notify you through prominent notice on our Website or
				via email.</p><br>
			<p><strong>4. Your Rights and Choices</strong></p><br>
			<p><strong>4.1 Access and Correction</strong>
				You have the right to access and correct the personal information we hold about you. You can do this by
				contacting us using the information provided at the end of this Privacy Policy.</p><br>
			<p><strong>4.2 Opt-Out</strong>
				You can opt-out of receiving marketing communications from us by following the instructions provided in
				the communication or by contacting us directly.</p><br>
			<p><strong>4.3 Do Not Track Signals</strong>
				Our Website does not respond to &quot;Do Not Track&quot; signals. However, you can disable certain
				tracking technologies through your browser settings.</p><br>
			<p>
			</p>
			<p><strong>Data Retention</strong></p>
			<p>We retain your personal information for as long as
				necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is
				required or permitted by law. We will securely delete or anonymize your information when it is no longer
				needed.</p> <br>
			<p>
			</p>
			<p><strong>Security</strong></p>
			<p>We take reasonable measures to protect your personal
				information from unauthorized access, disclosure, alteration, or destruction. However, no method of
				transmission or storage is 100% secure. While we strive to use commercially acceptable means to protect
				your information, we cannot guarantee its absolute security.</p><br>
			<p>
			</p>
			<p><strong>Children&#x27;s Privacy</strong></p>
			<p>Our Website is not intended for children under the age
				of 16. We do not knowingly collect personal information from children. If you believe that we may have
				inadvertently collected information from a child, please contact us using the information provided
				below, and we will promptly delete the information.</p><br>
			<p>
			</p>
			<p><strong>Third-Party Links</strong></p>
			<p>Our Website may contain links to third-party websites
				or services that are not owned or controlled by us. This Privacy Policy does not apply to those
				third-party websites or services. We encourage you to review the privacy policies of those third parties
				before providing any information or using their services.</p><br>
			<p>
			</p>
			<p><strong>Changes to this Privacy Policy</strong></p>
			<p>We may update this Privacy Policy from time to time.
				Any changes we make will be posted on this page, and the &quot;Last Updated&quot; date at the top of
				this Privacy Policy will be revised. We encourage you to review this Privacy Policy periodically for any
				updates.</p><br>
			<p>
			</p>
			<p>I<strong>nformation about General Data Protection
					Regulation (GDPR)</strong></p><br>
			<p>We may be collecting and using information from you if
				you are from the European Economic Area (EEA), and in this section of our Privacy Policy we are going to
				explain exactly how and why is this data collected, and how we maintain this data under protection from
				being replicated or used in the wrong way.</p><br>
			<p>
			</p>
			<p><strong>What is GDPR?</strong></p><br>
			<p>GDPR is an EU-wide privacy and data protection law
				that regulates how EU residents’ data is protected by companies and enhances the control the EU
				residents have, over their personal data.</p><br>
			<p>The GDPR is relevant to any globally operating company
				and not just the EU-based businesses and EU residents. Our customers’ data is important irrespective of
				where they are located, which is why we have implemented GDPR controls as our baseline standard for all
				our operations worldwide.</p><br>
			<p>
			</p>
			<p><strong>What is personal data?</strong></p><br>
			<p>Any data that relates to an identifiable or identified
				individual. GDPR covers a broad spectrum of information that could be used on its own, or in combination
				with other pieces of information, to identify a person. Personal data extends beyond a person’s name or
				email address. Some examples include financial information, political opinions, genetic data, biometric
				data, IP addresses, physical address, sexual orientation, and ethnicity.</p><br>
			<p>
			</p>
			<p><strong>The Data Protection Principles include
					requirements such as:</strong></p><br>
			<p>– Personal data collected must be processed in a fair,
				legal, and transparent way and should only be used in a way that a person would reasonably expect.</p>
			<p>– Personal data should only be collected to fulfil a
				specific purpose and it should only be used for that purpose. Organizations must specify why they need
				the personal data when they collect it.</p><br>
			<ul>
				<li style="list-style-type:disc">Personal data should be held no longer than necessary to fulfil its
					purpose.</li>
			</ul>
			<ul>
				<li style="list-style-type:disc">People covered by the GDPR have the right to access their own personal
					data. They can also request a copy of their data, and that their data be updated, deleted,
					restricted, or moved to another organization.</li>
			</ul>
			<p>
			</p>
			<p><strong>Why is GDPR important?</strong></p><br>
			<p>GDPR adds some new requirements regarding how
				companies should protect individuals’ personal data that they collect and process. It also raises the
				stakes for compliance by increasing enforcement and imposing greater fines for breach. Beyond these
				facts it’s simply the right thing to do. At Help Scout we strongly believe that your data privacy is
				very important and we already have solid security and privacy practices in place that go beyond the
				requirements of this new regulation.</p><br>
			<p>
			</p>
			<p><strong>Individual Data Subject’s Rights – Data
					Access, Portability and Deletion</strong></p><br>
			<p>We are committed to helping our customers meet
				the data subject rights requirements of GDPR. DevsWeek LLC processes or stores all personal data in
				fully vetted, DPA compliant vendors. We do store all conversation and personal data for up to 6 years
				unless your account is deleted. In which case, we dispose of all data in accordance with our Terms of
				Service and Privacy Policy, but we will not hold it longer than 60 days.</p><br>
			<p>We are aware that if you are working with EU
				customers, you need to be able to provide them with the ability to access, update, retrieve and remove
				personal data. We got you! We’ve been set up as self service from the start and have always given you
				access to your data and your customers data. Our customer support team is here for you to answer any
				questions you might have about working with the API.</p><br>
			<p>
			</p>
			<p><strong>California Residents</strong></p><br>
			<p>The California Consumer Privacy Act (CCPA) requires us
				to disclose categories of Personal Information we collect and how we use it, the categories of sources
				from whom we collect Personal Information, and the third parties with whom we share it, which we have
				explained above.</p>
			<p>We are also required to communicate information about
				rights California residents have under California law. You may exercise the following rights:</p>
			<p>– Right to Know and Access. You may submit a
				verifiable request for information regarding the: (1) categories of Personal Information we collect,
				use, or share; (2) purposes for which categories of Personal Information are collected or used by us;
				(3) categories of sources from which we collect Personal Information; and (4) specific pieces of
				Personal Information we have collected about you.</p>
			<p>– Right to Equal Service. We will not discriminate
				against you if you exercise your privacy rights.</p>
			<p>– Right to Delete. You may submit a verifiable request
				to close your account and we will delete Personal Information about you that we have collected.</p>
			<p>– Request that a business that sells a consumer’s
				personal data, not sell the consumer’s personal data.</p>
			<p>If you make a request, we have one month to respond to
				you. If you would like to exercise any of these rights, please contact us.</p>
			<p>We do not sell the Personal Information of our users.
			</p><br>
			<p>For more information about these rights, please
				contact us.</p><br>
			<p>
			</p>
			<p><strong>Contact Us</strong></p><br>
			<p>If you have any questions, concerns, or requests
				regarding this Privacy Policy or our data practices, please contact us at:</p>
			<p>– Via Email:  support@irontravelerlabs.com</p><br>
			<p>By using our Website, you acknowledge that you have
				read and understood this Privacy Policy and agree to its terms.</p>
		</div>

</template>

<script>
</script>

<style>

</style>