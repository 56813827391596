<template>
    <div>
        <h1>I-765 Application for Employment Authorization</h1>
    </div>
    <div>
        <p>The I-765 is the Application for Employment Authorization issued by the United States Citizenship and Immigration
            Services (USCIS). It is commonly known as the Employment Authorization Document (EAD) or work permit.</p><br>
        <p>The purpose of the I-765 form is to request permission to work legally in the United States for certain
            nonimmigrant categories, as well as for individuals applying for adjustment of status to permanent residence
            (green card). Here are a few key points about the I-765:</p><br>
        <ul>
            <li><strong>Eligibility:</strong> The I-765 is generally filed by individuals who are in the United States and have a qualifying
                immigration status that allows them to apply for employment authorization. Common examples include
                individuals on F-1 student visas, J-2 dependents of exchange visitors, spouses of certain visa holders, and
                those with pending applications for adjustment of status.</li>
            <li><strong>Supporting Documentation:</strong> When filing the I-765, you typically need to provide supporting documents that
                establish your eligibility for employment authorization. These may include copies of your passport, visa,
                I-94 Arrival/Departure Record, I-20 (for students), or other relevant immigration documents.</li>
            <li><strong>Filing Fee and Processing Time:</strong> There is a filing fee associated with the I-765, which is subject to change.
                As for the processing time, it can vary, but USCIS generally provides an estimated timeframe on their
            website. It's essential to check the USCIS website or contact USCIS directly for the most up-to-date
            information.</li>
        <li><strong>Employment Authorization Document (EAD):</strong> If your application is approved, you will receive an Employment
            Authorization Document (EAD), commonly known as a work permit. The EAD allows you to legally work in the
            United States for a specific period of time.</li>
        <li><strong>Renewal:</strong> The EAD has an expiration date, so if you want to continue working legally, you must file a renewal
            application well in advance. USCIS recommends filing the renewal application at least 120 days before your
            current EAD expires.</li>
        <li><strong>Updates and Changes:</strong> It's important to keep USCIS informed of any changes in your personal information, such
            as address, name, or marital status. You can request an updated EAD if any of these changes occur.</li>
    </ul><br>

    <p>Remember, the information provided here is a general overview of the I-765 process. The specific requirements and
        procedures may vary depending on your immigration status and circumstances. It's always advisable to consult the
        USCIS website or seek professional legal advice for personalized guidance on your particular situation.</p>
</div></template>